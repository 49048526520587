@import "../index.scss";

.container {
  position: relative;
  max-width: 140rem;
  margin: 0 auto;
  min-height: 100vh;
  @media (max-width: 120em) {
    width: 100vw;
  }
  @media (max-width: 64em) {
    min-height: 0;
  }
}
.menuPosition {
  // display: flex;
  // flex-direction: row;
  display: grid;
  grid-template-columns: 2.3fr 7fr;

  @media (max-width: 60em) {
    //display: flex;
    //flex-direction: column;
    grid-template-columns: 1fr;
  }
}

.content {
  background-color: $background-primary-color;
  width: 100%;
  box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  @media (max-width: 52em) {
    z-index: 0;
  }
}
