@import "../index.scss";

.list {
    margin-left: 2rem;

    & ul {
        padding-left: 3rem;


    }

    & li {
        margin-bottom: 0.8rem;
    }


    & span {
        color: $theme-secondary;
        font-weight: 500;
    }
}

.highlightOrange {
    color: $background-primary-color-dark !important;
    font-weight: 600;
    //text-shadow: 0.7px 0.7px 0.7px #555;
}

.highlightBlue {
    color: $theme-secondary !important;
    font-weight: 500;
}

.link {
    color: $theme-primary;
    font-weight: 500;
    padding: 0 1rem;

    &:hover {
        background-color: $theme-primary;
        color: #fff;
        text-decoration: none;
    }
}