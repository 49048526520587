@import "../index.scss";

.about {
  display: flex;

  //background-color: $background-secondary;
  background-color: inherit;
  width: 100%;
}

.container {
  display: flex;
  background-color: rgba(250, 250, 250, 0.3);
  flex-direction: column;
  //width: 140rem;
  //padding: 4rem 4rem 8rem 4rem;
  padding: 2rem;
  margin: 0 auto;

  @media (max-width: 64em) {
    width: 100%;
  }
}

.photo {
  width: 12rem;
  float: left;
  margin-right: 2rem;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: 64em) {
    width: 14rem;
  }
}

.about img {
  width: 100%;
}

.about p {
  //color: #fff;
  color: #333;
  text-align: justify;
  font-size: 1.6rem;
  //font-weight: 300;
  font-weight: 500;
  //background-color: rgba(250, 250, 250, 0.5);

  @media (max-width: 64em) {
    font-size: 2rem;
  }

  @media (max-width: 30em) {
    font-size: 1.8rem;
    line-height: 1.3;
  }
}

.about h1 {
  //color: #fff !important;
  color: $theme-secondary;
  font-size: 2.8rem;
  font-weight: 400;

  @media (max-width: 64em) {
    font-size: 3.2rem;
  }
}
