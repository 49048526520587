@import "../index.scss";

.paragraphTitle {
  //font-family: "Oswald", Roboto, Arial, Helvetica, sans-serif;
  color: $theme-secondary;
  text-decoration: underline;
  font-size: 1.6rem;
  //font-family: "Oswald";
  font-weight: 600;

  @media (max-width: 64em) {
    font-size: 2rem;
  }

  @media (max-width: 44em) {
    font-size: 2rem;
  }

  @media (max-width: 30em) {
    font-size: 1.6rem;
  }
}

.link {
  color: $theme-primary;
  font-weight: 500;
  padding: 0 1rem;

  &:hover {
    background-color: $theme-primary;
    color: #fff;
    text-decoration: none;
  }
}

.highlight {
  color: $theme-secondary;
  font-weight: 600;
}

.highlightItalic {
  color: $theme-secondary;
  font-weight: 600;
  font-style: italic;
}

.list {
  margin-left: 1rem;

  & ul {
    padding-left: 3rem;
  }

  & span {
    color: $theme-secondary;
    font-weight: 500;
  }
}