@import "../index.scss";

.paragraphTitle {
  //font-family: "Oswald", Roboto, Arial, Helvetica, sans-serif;
  color: $theme-secondary;
  text-decoration: underline;
  font-size: 1.6rem;
  //font-family: "Oswald";
  font-weight: 400;
  margin-bottom: 0.5rem;
  cursor: pointer;
  &:hover {
    color: $theme-primary;
    font-weight: 500;
  }

  @media (max-width: 52em) {
    padding-left: 2rem;
    font-size: 1.8rem;
  }

  @media (max-width: 30em) {
    font-size: 2rem;
    padding-left: 0;
  }
}

.city {
  color: $background-primary-color-dark !important;
  text-decoration: underline;
}

.bookSection {
  margin-bottom: 2rem;
}
.book {
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: $background-primary-color-dark;
  @media (max-width: 30em) {
    font-size: 2rem;
  }
}
.bookOther {
  font-size: 1.6rem;
  color: #333;
  margin-left: 1rem;
  @media (max-width: 56em) {
    font-size: 1.8rem;
  }
}
.bookComment {
  font-size: 1.6rem;
  color: #666;
  @media (max-width: 56em) {
    font-size: 1.8rem;
  }
}
.readingDates {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(3, auto);
  grid-auto-flow: column;
  //column-gap: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
  column-gap: 5rem;
  @media (max-width: 64em) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
  }
  @media (max-width: 52em) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, auto);
    width: 80%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  @media (max-width: 30em) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    justify-content: center;
    width: 100%;
    margin: 0 0 2rem 0;
  }
}
.readingBox {
  padding: 2rem;
  border: 1px solid $background-primary-color-dark;
  border-radius: 6px;
}
.readingComment {
  font-size: 1.4rem;
  text-decoration: underline;
  color: #666;
  margin-bottom: 1rem;
}

.link {
  //display: inline-block;
  text-decoration: none;
  background-color: $theme-primary;
  padding: 0.3rem 1rem;
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: 2rem;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    //color: $theme-secondary;
    text-decoration: underline;
    color: $theme-secondary;
  }

  @media (max-width: 30em) {
    font-size: 1.8rem;
  }
}
