@import "../index.scss";

.backgroundPicture {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  padding-top: 2rem;
  //width: 100%;
  height: 100%;
  background-image: url("../img/Greece/GreeceTrip_small.jpg");
  background-size: cover;
  @media (min-resolution: 192dpi) and (min-width: 37.5em), (min-width: 125em) {
    background-image: url("./../img/Greece/GreeceTrip.jpg");
  }

  @media (max-width: 60rem) {
    min-height: 100vh;
  }
}

.backgroundPictureIsrael {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  padding-top: 2rem;
  //width: 100%;
  height: 100%;
  background-image: url("../img/Israel/Israel_1_small.jpg");
  background-size: cover;
  @media (min-resolution: 192dpi) and (min-width: 37.5em), (min-width: 125em) {
    background-image: url("../img/Israel/Israel_1.jpg");
  }
  @media (max-width: 60rem) {
    min-height: 100vh;
  }
}
.backgroundPicturePatmos {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  padding-top: 2rem;
  //width: 100%;
  height: 100%;
  //background-image: url(../img/Patmos/Home-large.jpg);
  background-image: image-set(
    url("../img/Patmos/Home-small.avif") type("image/avif"),
    url("../img/Patmos/Home-small.jpg") type("image/jpeg")
  );
  background-size: cover;

  @media (min-resolution: 192dpi) and (min-width: 37.5em), (min-width: 125em) {
    background-image: image-set(
      url("../img/Patmos/Home-large.avif") type("image/avif"),
      url("../img/Patmos/Home-large.jpg") type("image/jpeg")
    );
  }

  @media (max-width: 60rem) {
    min-height: 100vh;
    justify-content: flex-start;
    padding-top: 6rem;
  }
  @media (max-width: 48rem) {
    padding-top: 0rem;
  }
}

.backgroundPictureItaly {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  padding-top: 2rem;
  //width: 100%;
  height: 100%;
  background-image: url(../img/Italy/Gallery_4.jpeg);
  background-size: cover;

  @media (max-width: 60rem) {
    min-height: 100vh;
  }
}

.backgroundPicturePoland {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  padding-top: 2rem;
  //width: 100%;
  height: 100%;
  background-image: url(../img/Poland/Gallery_2.jpeg);
  background-size: cover;

  @media (max-width: 60rem) {
    min-height: 100vh;
  }
}

.backgroundPictureSwitz {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  padding-top: 2rem;
  //width: 100%;
  height: 100%;
  background-image: url(../img/Switzerland/Gallery_5.jpeg);
  background-size: cover;

  @media (max-width: 60rem) {
    min-height: 100vh;
  }
}

.backgroundPictureTurkey {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  padding-top: 2rem;
  //width: 100%;
  height: 100%;
  background-image: url(../img/Turkey/Gallery_9_Library_Celsus.jpg);
  background-size: cover;

  @media (max-width: 60rem) {
    min-height: 100vh;
  }
}

.backgroundPictureEngland {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  padding-top: 2rem;
  //width: 100%;
  height: 100%;
  background-image: url(../img/England/Main.jpeg);
  background-size: cover;

  @media (max-width: 60rem) {
    min-height: 100vh;
  }
}

.backgroundPictureGermany {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  //padding-top: 4rem;
  padding: 4rem 0;
  //width: 100%;
  height: 100%;
  background-image: url(../img/Germany/Intro.jpeg);
  background-size: cover;

  @media (max-width: 60rem) {
    min-height: 100vh;
    justify-content: flex-start;
  }
}

.titleBox {
  color: $theme-secondary;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  width: 80%;
  //height: 50%;
  //margin-top: 8rem;
  //margin-top: 2rem;
  text-align: center;
  padding-top: 2rem;
  margin-bottom: 2rem;
  background-color: rgba(250, 250, 250, 0.6);
  box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.4);

  & h1 {
    font-size: 3rem;
    font-weight: 400;
    @media (max-width: 30em) {
      font-size: 2.6rem;
    }
  }

  @media (max-width: 64em) {
    font-size: 2rem;
    //margin-top: 2rem;
    //height: 56rem;
  }

  @media (max-width: 44em) {
    width: 90%;
    font-size: 2rem;
    margin-top: 4rem;
    //height: 50%;
  }

  @media (max-width: 30em) {
    width: 100%;
    font-size: 1.8rem;
    margin-top: 2rem;
    line-height: 1.4;
  }
  // @media (max-width: 22.5em) {
  //   font-size: 1.4rem;
  // }

  .intro {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 3rem;

    // @media (max-width: 60em) {
    //   font-size: 3.4rem;
    //   font-weight: 500;
    // }
  }

  .title {
    font-family: "Oswald", Roboto, Arial, Helvetica, sans-serif;
    font-size: 5.6rem;
    font-weight: 600;

    @media (max-width: 64em) {
      font-size: 6rem;
    }

    @media (max-width: 44em) {
      font-size: 5rem;
    }
    @media (max-width: 30em) {
      font-size: 4rem;
    }
  }

  .dates {
    font-family: "Oswald";
    font-weight: 500;
    letter-spacing: 1px;
    color: $text;

    @media (max-width: 60em) {
      font-size: 3rem;
    }

    @media (max-width: 48em) {
      font-size: 2.4rem;
    }

    @media (max-width: 30em) {
      font-size: 2.6rem;
    }
    @media (max-width: 22.5em) {
      font-size: 2rem;
    }
  }

  .description {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 2rem;
    padding: 1.6rem;
    //font-style: italic;
    //color: $text;
    background-color: rgba(250, 249, 219, 0.726);

    // @media (max-width: 60em) {
    //   font-size: 3rem;
    // }

    // @media (max-width: 44em) {
    //   font-size: 2.6rem;
    // }

    @media (max-width: 30em) {
      font-size: 2rem;
    }
  }
}

.guideSection {
  position: absolute;
  bottom: 0;
  left: 0;
}
