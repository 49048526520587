@import "../index.scss";

.modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  //background: rgba(28, 28, 28, 0.19);
  background: linear-gradient(rgba(57, 72, 90, 0.97), rgba(57, 72, 90, 0.97));
  backdrop-filter: blur(6px);
  opacity: 1;
  // animation-timing-function: ease-out;
  // animation-duration: 0.3s;
  // animation-name: modal-video;
  // -webkit-transition: opacity 0.3s ease-out;
  // -moz-transition: opacity 0.3s ease-out;
  // -ms-transition: opacity 0.3s ease-out;
  // -o-transition: opacity 0.3s ease-out;
  //transition: opacity 0.3s ease-out;
  z-index: 100;
}

.modal__align {
  display: flex;
  width: 85.3rem;
  height: 48rem;
  //   width: 80%;
  //   height: 80%;
  position: relative;
  justify-content: center;
  align-items: center;
  // box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
  //     0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
  //     0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
  //     0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
  //border-radius: 20px;
  //background: transparent;
  //color: #000;
  //margin: 0rem 4rem;

  @media (max-width: 48em) {
    width: 56rem;
    height: 31.5rem;
  }

  @media (max-width: 40em) {
    width: 31.2rem;
    height: 17.6rem;
  }
}

.modal__close {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  //position: relative;
  position: absolute;
  top: 0;
  right: 0;
  //bottom: 50px;
  width: 32px;
  height: 32px;
  padding: 0;
  transform: translateY(-40px);
}

.videoGroup {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-auto-flow: column;
  justify-content: center;
  width: 94%;
  margin: 0 auto;
  margin-bottom: 2rem;

  @media (max-width: 52em) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, auto);
    justify-items: center;
    align-items: center;
  }
  @media (max-width: 30em) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, auto);

    //column-gap: 2rem;
  }
  // @media (max-width: 30em) {
  //   grid-template-columns: repeat(1, auto);
  //   grid-auto-flow: row;
  //   justify-content: center;
  //   width: 100%;
  //   margin-bottom: 2rem;
  // }
}

.videoGroupTitleIsrael {
  //padding-left: 4rem;
  text-align: left !important;
  font-weight: 400 !important;
  text-decoration: underline;
  color: $theme-secondary;
  font-size: 1.6rem;

  @media (max-width: 30em) {
    font-size: 1.8rem;
  }

  &:hover {
    cursor: pointer;
    color: $theme-primary;
  }
}
.videoGroupBox {
  border: 1px solid $theme-primary;
  border-radius: 4px;
  padding: 1rem;
  @media (max-width: 37.5em) {
    padding: 0.8rem;
  }
}

.videoTitle {
  border: none;
  padding: 0.4rem;
  box-shadow: 2px 2px 6px rgba(184, 184, 184, 0.7);
  width: 99%;
  background-color: transparent;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  color: $theme-secondary;
  margin-bottom: 2rem;

  //font-style: italic;
  @media (max-width: 52em) {
    font-size: 1.8rem;
  }
  @media (max-width: 30em) {
    font-size: 2rem;
  }

  &:hover {
    color: $theme-primary;
    cursor: pointer;
    box-shadow: inset 2px 2px 6px rgba(184, 184, 184, 0.7);
  }
}

.videoTime {
  font-size: 1.6rem;
  color: #666;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.subtitle {
  text-align: start !important;
  font-style: normal !important;
  font-weight: 500 !important;
  margin-bottom: 1rem !important;
}

.bookList {
  margin-left: 4rem;
  margin-bottom: 2rem;
}

.bookList li {
  margin-bottom: 1rem;
}

.bookList span {
  //font-weight: 500;
  text-decoration: underline;
}

.link {
  color: $theme-primary;
  font-weight: 500;
  padding: 0 1rem;

  &:hover {
    background-color: $theme-primary;
    color: #fff;
    text-decoration: none;
  }
}
