@import "../index.scss";

.dayNumber {
  //font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: $theme-secondary;
  text-decoration: underline;
  font-size: 1.6rem;
  font-weight: 400;

  // @media (max-width: 64em) {
  //   font-size: 1.8rem;
  // }

  // @media (max-width: 44em) {
  //   font-size: 2.2rem;
  // }

  @media (max-width: 30em) {
    font-size: 1.8rem;
  }
}

.popUpBtn {
  padding-left: 2rem;
  background-color: inherit;
  font-size: 1.8rem;
  font-weight: 600;
  border: none;
  color: $theme-primary;
  cursor: pointer;

  &:hover {
    //color: $theme-secondary;
    text-decoration: underline;
  }

  @media (max-width: 30em) {
    font-size: 1.8rem;
  }
}
.popUpPictureBtn {
  width: 100%;
  border: none;
  cursor: pointer;
  width: 14rem;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.3);
  }

  & img {
    width: 100%;
  }
}

.justPicture {
  width: 100%;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  //   80% {
  //     transform: translateX(20px);
  //   }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.popUpBox {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  //padding-bottom: 40rem;
  background: linear-gradient(rgba(57, 72, 90, 0.97), rgba(57, 72, 90, 0.97));
  animation: moveInLeft 0.5s ease-in-out;
  z-index: 999;

  @media (max-width: 68em) {
    padding-top: 14rem;
    display: flex;
    align-items: start;
    height: auto;
    z-index: 999;
    overflow: scroll;
  }

  @media (max-width: 40rem) {
    padding-top: 10rem;
  }
}

.popUpBoxLong {
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 6rem;
  padding-bottom: 6rem;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  //padding-bottom: 40rem;
  background: linear-gradient(rgba(57, 72, 90, 0.97), rgba(57, 72, 90, 0.97));
  animation: moveInLeft 0.5s ease-in-out;
  z-index: 999;
  overflow: scroll;

  @media (max-width: 60rem) {
    padding-top: 14rem;
  }

  @media (max-width: 40rem) {
    padding-top: 10rem;
  }
}

.innerBox {
  position: relative;
  width: 65%;
  background-color: $background;
  padding: 4rem;
  //margin-bottom: 20rem;
  //margin-top: 6rem;
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 66em) {
    width: 85%;
  }

  @media (max-width: 30em) {
    //margin-top: 30rem;
    width: 95%;
    padding: 4rem 1rem;
    height: max-content;
    overflow: scroll;
  }
}

.innerBoxPicture {
  position: relative;
  width: 54%;
  //background-color: $background;
  //padding: 1rem;
  //margin-bottom: 20rem;
  //margin-top: 6rem;
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 116em) {
    width: 60%;
  }

  @media (max-width: 86em) {
    width: 70%;
  }

  @media (max-width: 66em) {
    width: 85%;
  }

  @media (max-width: 30em) {
    //margin-top: 30rem;
    width: 95%;
    padding: 4rem 1rem;
    height: max-content;
    overflow: scroll;
  }
}

.pictureFloat {
  width: 60%;
  float: right;
  margin-left: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 60em) {
    width: 100%;
    float: none;
    margin-bottom: 2rem;
    margin-left: 0;
  }
}

.picture {
  width: 100%;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.icon {
  font-size: 4rem;
  position: absolute;
  top: -4rem;
  right: -4rem;
  color: $theme-primary;
  cursor: pointer;

  &:hover {
    color: $background;
  }

  @media (max-width: 60em) {
    top: -6rem;
    right: -6rem;
    font-size: 5rem;
  }

  @media (max-width: 48em) {
    top: -4rem;
    right: -4rem;
    font-size: 4.4rem;
  }

  @media (max-width: 30em) {
    top: 2px;
    right: 2px;
    font-size: 3.2rem;
  }
}

.pictureBox {
  display: flex;
  flex-direction: row;
  gap: 0;

  @media (max-width: 40em) {
    flex-direction: column;
  }
}

.picture {
  width: 100%;
}

.hide {
  @media (max-width: 44em) {
    display: none;
  }
}
