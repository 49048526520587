@import "./index.scss";

.container {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 20%;
  //align-self: center;
  padding: 2rem 4rem;

  @media (max-width: 64em) {
    width: 25%;
    padding: 1rem;
  }

  @media (max-width: 44em) {
    padding: 1rem 0;
    width: 30%;
  }

  @media (max-width: 40em) {
    padding: 0.5rem 0;
    width: 35%;
  }
}

.trips {
  display: flex;
  padding: 2rem 0 4rem 0;
  background-color: $background-secondary;

  @media (max-width: 64em) {
    padding: 2.2rem 0 2rem 0;
  }
}

.trips h1 {
  font-family: "Oswald", Roboto, sans-serif;
  color: #fff;
  font-size: 5rem;
  text-transform: uppercase;

  @media (max-width: 64em) {
    font-size: 4rem;
  }

  @media (max-width: 48em) {
    font-size: 3.6rem;
  }

  @media (max-width: 44em) {
    font-size: 3rem;
  }

  @media (max-width: 30em) {
    font-size: 2.4rem;
  }
}

.tripLinks {
  display: flex;
  flex-direction: column;
  width: 120rem;
  margin: 0 auto;
}

.tripsGrid {
  padding: 2.6rem 2rem;
  //display: flex;
  // align-items: center;
  // justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 44em) {
    grid-template-columns: 1fr;
    justify-items: self-start;
    align-items: center;
    //padding: 0;
    //gap: 1rem;
    padding-left: 10%;
    //margin-top: 2rem;
  }
}

.listItem {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  //align-items: center;
  //justify-content: center;
  font-size: 2.4rem;
  color: #fff;
  padding-left: 4rem;
  margin-bottom: 1rem;

  @media (max-width: 64em) {
    font-size: 2rem;
    padding-left: 5%;
  }

  @media (max-width: 48em) {
    font-size: 1.8rem;
  }

  @media (max-width: 44em) {
    padding-left: 0;
  }
}

.circle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #fff;
  margin-top: 1.4rem;

  @media (max-width: 64em) {
    width: 1.8;
    height: 1.8;
    margin-top: 1rem;
  }

  @media (max-width: 30em) {
    margin-top: 0.6rem;
  }
}

.container a:link,
.container a:visited {
  display: block;
  text-decoration: none;
  border-radius: 5px;
  color: $background;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 48em) {
    //padding-bottom: 0.5rem;
    font-size: 2rem;
  }

  @media (max-width: 44em) {
    font-size: 1.8rem;
  }

  // @media (max-width: 30em) {
  //   font-size: 1.8rem;
  // }
}

.container a:visited,
.container a:hover {
  text-decoration: underline;
}

.pictures {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0;

  @media (max-width: 60em) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 44em) {
    grid-template-columns: 1fr;
  }
}

.pictures img {
  width: 100%;
}

.extraPicture {
  display: none;

  @media (max-width: 60em) {
    display: inline-block;
  }
}
