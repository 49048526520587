@import "../index.scss";

.menu {
  position: relative;
  display: flex;
  flex-direction: column;
  //width: 35rem;
  height: 100vh;
  z-index: 1;
  background-color: $background;

  @media (max-width: 60em) {
    display: grid;
    grid-template-columns: 1fr 4fr;
    height: 100%;
  }
  @media (max-width: 52em) {
    //change to higgen menu 48
    //position: fixed;
    grid-template-columns: 1fr 3fr;
    height: 8rem;
  }
  @media (max-width: 30em) {
    height: 6rem;
  }
}
.logo {
  width: 80%;
  @media (max-width: 60em) {
    width: 100%;
    //padding: 0;
  }
  @media (max-width: 52em) {
    //change to hidden menu 48
    width: 100%;
  }
  @media (max-width: 30em) {
    width: 120%;
  }
}
.nav {
  margin-top: 2rem;
  margin-bottom: 1.6rem;
  //background-color: $background-primary-color;
  @media (max-width: 60em) {
    margin-top: 2rem;
  }
  @media (max-width: 52em) {
    // change to hidden menu 48
    margin-top: 4rem;
  }
}
.homeBox {
  margin-top: 2rem;
  //margin-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 60em) {
    margin-top: 1rem;
    //margin-right: 2rem;
    gap: 0.8rem;
  }
  @media (max-width: 52em) {
    //change to hidden menu 48
    flex-direction: row;
    margin-right: 0;
    justify-content: space-evenly;
  }
}
.home {
  //align-self: center;
  font-family: "Oswald", Roboto, Helvetica;
  color: $theme-secondary;
  font-size: 2.8rem;
  font-weight: 400;
  letter-spacing: 2px;
  align-self: center;
  &:hover {
    color: $theme-primary;
    text-decoration: underline;
  }
  @media (max-width: 60em) {
    transform: translate(20px, -20px);
    font-size: 2.6rem;
    top: 2rem;
  }
  @media (max-width: 52em) {
    ///change to hidden menu, 48
    transform: translate(0, 0);
    position: absolute;
    top: 14%;
    left: 180%;
    font-size: 3.2rem;
  }
  @media (max-width: 30em) {
    font-size: 2.2rem;
    top: 10%;
  }
}

.nav ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  @media (max-width: 60em) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    //column-gap: 1.6rem;
  }
  // @media (max-width: 48em) {
  //   display: flex;
  //   flex-direction: column;
  //   justify-items: center;
  //   gap: 0.5rem;
  // }
  @media (max-width: 52em) {
    // change to hidden menu 48
    position: absolute;
    top: 0rem;
    left: 0;
    width: 100%;
    height: 133rem;
    background-color: $background-primary-color-dark;
    color: $background;
    //opacity: 0.98;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 6rem 0;
    box-shadow: 0px 2px 3px 5px rgba(0, 0, 0, 0.8);
    transition: all 0.5s;
    padding-left: 0;
    //z-index: 1;

    //border-bottom: 2px solid $theme-primary;
  }
  @media (max-width: 30em) {
    padding-top: 8rem;
  }
}

.nav a:link,
.nav a:visited {
  //font-family: "Oswald", Roboto, Arial, Helvetica, sans-serif;
  display: block;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 400;
  padding: 0.5rem 0.5rem 0.5rem 4rem;
  border-radius: 5px;
  color: $theme-secondary;

  @media (max-width: 73em) {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    font-size: 2.2rem;
  }
  @media (max-width: 64em) {
    font-family: "Oswald", Roboto, Arial, Helvetica, sans-serif;
    font-size: 2.4rem;
  }
  @media (max-width: 52em) {
    //change to hidden menu
    padding-bottom: 0.5rem;
    font-size: 4rem;
    color: $background;
  }
  @media (max-width: 44em) {
    font-size: 4.6rem;
  }
  @media (max-width: 30em) {
    font-size: 3rem;
  }
}
.nav a:active,
.nav a:hover {
  text-decoration: underline;
  color: $theme-primary;
}

.btn {
  position: absolute;
  top: 4rem;
  right: 4rem;
  font-size: 6rem;
  border: none;
  background: none;
  cursor: pointer;
  color: $theme-secondary;
  display: none;

  @media (max-width: 52em) {
    // change to hidden menu
    display: block;
    top: 1.4rem;
    right: 2rem;
    font-size: 6rem;
  }
  @media (max-width: 30em) {
    top: 1.2rem;
    right: 1.6rem;
    font-size: 4rem;
  }
}
.btnHidden {
  @media (max-width: 52em) {
    //change to hidden menu
    display: none;
  }
}
.btnVisible {
  @media (max-width: 52em) {
    display: block;
  }
}

.navHidden {
  @media (max-width: 52em) {
    display: none;
  }
}
.navOpen {
  @media (max-width: 52em) {
    display: block;
  }
}
