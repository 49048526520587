@import "../index.scss";

.footer {
  display: flex;
  background-color: $background-secondary;
  width: 100vw;
  @media (max-width: 60em) {
    height: 60vh;
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120rem;
  height: fit-content;
  padding: 4rem 8rem 8rem 4rem;
  margin: 0 auto;

  @media (max-width: 64em) {
    flex-direction: column;
    gap: 1.4rem;
    width: 100%;
  }

  @media (max-width: 48em) {
    padding: 4rem 1rem 8rem 2rem;
  }
}

// .photo {
//   width: 12rem;
//   float: left;
//   margin-right: 2rem;
//   margin-bottom: 1rem;
// }

// .photo img {
//   width: 100%;
// }

.footer p {
  color: #fff;
  //text-align: justify;
  font-size: 1.4rem;
  font-weight: 300;

  @media (max-width: 64em) {
    font-size: 1.8rem;
  }

  // @media (max-width: 30em) {
  //   font-size: 1.6rem;
  // }
}

.footer h1 {
  color: #fff !important;
  font-size: 2.8rem;
  font-weight: 400;
}

.footer a {
  color: $theme-primary;
  //font-weight: 500;
  //padding: 0.4rem 1.6rem;
  //margin-left: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;

  &:hover {
    color: #fff;
    //color: $theme-secondary;
  }
}

.email {
  color: $theme-primary;
  //font-weight: 500;
  // padding: 0.4rem 1.6rem;
}

.item {
  font-size: 1.4rem;
  font-weight: 300;
}
