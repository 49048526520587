@import "~react-image-gallery/styles/scss/image-gallery.scss";

.gallery-container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 4rem;

  @media (max-width: 64em) {
    margin-top: 2rem;
    margin-bottom: 8rem;
  }
  @media (max-width: 52em) {
    margin-top: 2rem;
    margin-bottom: 16rem;
  }
  @media (max-width: 22.5em) {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  // overflow: scroll;
}

// .pictures {
//   width: 100%;
// }
.image-gallery-wrapper {
  //display: flex;
  margin-top: 5rem;
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;
  //border: 1px solid rgb(146, 129, 242);
  box-shadow: #2b15453d 1px 10px 10px 5px;
}

// .space {
//   height: 64rem;
//   @media (max-width: 30em) {
//     height: 40rem;
//   }
// }
