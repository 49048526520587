@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Oswald:wght@400;500;600&family=Roboto:wght@300;400;500;700&display=swap");

$theme-primary: #f4aa00;
//$yellow-lighter: #fff3bf;
//$yellow-darker: #e67700;
$background: #fff;
$background-secondary: rgb(57, 72, 90);
$background-secondary-footer: rgb(57, 72, 90);
$background-primary-color: #fef7e6;
$background-primary-color-dark: #c38800;
//$grey-dark: #343a40;
$text: #333;
$theme-secondary: rgb(0, 38, 100);

// $yellow: #f8bc4d;
// $yellow-lighter: #fff3bf;
// $yellow-darker: #f6a91a;
// $yellow-light-background: rgb(255, 249, 219);
// $yellow-dark-background: rgb(147, 95, 0);
// $grey-dark: #343a40;
// $yellow-text: rgb(255, 243, 191);
// $yellow-text-dark: #7b5000;

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  //font-size: calc(15px + 0.390625vw);
  box-sizing: border-box;

  @media (max-width: 160em) {
    font-size: 70%;
  }

  // @media (max-width: 120em) {
  //   font-size: 23px;
  // }
  // @media (max-width: 96em) {
  //   font-size: 21px;
  // }
  // @media (max-width: 80em) {
  //   font-size: 20px;
  // }
  @media (max-width: 60em) {
    font-size: 60%;
  }

  @media (max-width: 44em) {
    font-size: 58%;
  }

  // @media (max-width: 20em) {
  //   font-size: 16px;
  // }
}

body {
  font-family: "Roboto", Helvetica, Arial sans-serif;
  background-color: $background;
  font-weight: 400;
  line-height: 1.6;
}
button {
  font-size: 62.5%;
  line-height: 1.15;
  margin: 0;
  font-family: inherit;
}

//global styles for pages

.pageContainer {
  position: relative;
  padding: 4rem 6rem;
  width: 100%;

  @media (max-width: 64em) {
    padding: 2rem 3rem;
  }

  @media (max-width: 48em) {
    padding: 1.6rem 2.6rem;
  }

  @media (max-width: 30em) {
    padding: 0.5rem 1.8rem;
  }
}

.title {
  font-family: "Oswald";
  font-size: 3rem;
  font-weight: 500;
  //text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: $theme-secondary;

  @media (max-width: 64em) {
    font-size: 3.4rem;
  }

  @media (max-width: 60em) {
    font-size: 3.6rem;
  }

  // @media (max-width: 48em) {
  //   margin-bottom: 0.5rem;
  //   font-size: 5rem;
  // }

  @media (max-width: 40em) {
    font-size: 4rem;
  }

  @media (max-width: 30em) {
    margin-bottom: 0.5rem;
    font-size: 2.8rem;
  }
  @media (max-width: 22.5em) {
    font-size: 2.4rem;
  }
}

.secondaryTitle {
  font-family: "Oswald";
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;
  color: $theme-secondary;
  margin-bottom: 2rem;
  //font-style: italic;

  // @media (max-width: 64em) {
  //   font-size: 2rem;
  // }

  // @media (max-width: 60em) {
  //   font-size: 2.2rem;
  // }

  @media (max-width: 52em) {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 30em) {
    margin-bottom: 1rem;
    font-size: 2.2rem;
  }
}

.text {
  font-size: 1.6rem;
  color: $text;
  line-height: 1.4;
  margin-bottom: 2rem;
  font-weight: 400;
  // @media (max-width: 52em) {
  //   font-size: 1.6rem;
  // }
  @media (max-width: 30em) {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
}

.printBox {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-bottom: 2rem;
}

.print {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  background-color: $theme-primary;
  justify-self: end;
}

.print a {
  font-size: 1.8rem;
  text-decoration: none;
  color: #fff;
  font-weight: 500;

  &:hover {
    color: $theme-secondary;
  }
}

.emptyDiv {
  height: 58rem;

  @media (max-width: 48em) {
    height: 30em;
  }
}

.pictureRow {
  display: flex;
  flex-direction: row;
  gap: 0;
  margin-bottom: 3rem;

  @media (max-width: 40em) {
    flex-direction: column;
  }
}

.picture {
  width: 100%;
}

.hide {
  @media (max-width: 44em) {
    display: none;
  }
}

.margin-left {
  margin-left: 4rem;

  @media (max-width: 40em) {
    margin-left: 2rem;
  }
}

.margin-bottom {
  margin-bottom: 4rem;

  @media (max-width: 30em) {
    margin-bottom: 3rem;
  }
}
