.pictureBox {
  display: flex;
  flex-direction: row;
  gap: 0;

  @media (max-width: 40em) {
    flex-direction: column;
  }
}
.picture {
  width: 100%;
}

.hide {
  @media (max-width: 44em) {
    display: none;
  }
}
