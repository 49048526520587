@import "../index.scss";

.message {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.goBack {
  font-size: 2.6rem;
  padding: 1rem 4rem;
  font-weight: 600;
  background-color: $theme-primary;
  color: $background;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
